<template>
    <div class="message-list-item" :class="{ disabled: disabled }" @click="message_clickHandler">
        <Avatar
            :label="initials"
            size="xlarge"
            :class="!unreadCount ? 'read' : ''"
            :style="selfStyle"
            v-badge.success="unreadCount"
        />
        <div class="message-body">
            <span class="profile-name">{{ firstName }} {{ lastName }}</span>
            <span :class="selfClass">{{ latestMessageText }}</span>
        </div>
        <span class="message-sent">{{ sentDate }}</span>
    </div>
</template>

<script>
import moment from 'moment'
import { createNamespacedHelpers } from 'vuex'

const portalVuex = createNamespacedHelpers('portal')

export default {
    components: {},
    data() {
        return {}
    },
    props: {
        channel: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {},
    computed: {
        ...portalVuex.mapGetters(['userId']),
        selfClass() {
            return `message-content ${this.unreadCount > 0 ? 'unread' : ''}`
        },
        selfStyle() {
            return {
                backgroundColor: this.isAssignedToMe ? '#0073E6' : '#999',
                color: '#ffffff',
            }
        },
        initials() {
            return this.firstName.charAt(0) + this.lastName.charAt(0)
        },
        firstName() {
            return this.channel?.patientFirstName
        },
        lastName() {
            return this.channel?.patientLastName
        },
        latestMessageText() {
            return this.channel?.lastMessage?.message
        },
        isAssignedToMe() {
            return this.channel?.owner === this.userId
        },
        unreadCount() {
            return this.channel?.unreadMessages
        },
        sentDate() {
            if (this.channel.lastMessage) {
                const messageSentTime = moment(this.channel.lastMessage.lastModifiedDate)
                const currentTime = moment()

                const hoursDifference = currentTime.diff(messageSentTime, 'hours')

                if (hoursDifference >= 24) {
                    return messageSentTime.format('MMM DD')
                } else {
                    messageSentTime.locale('en', {
                        relativeTime: {
                            future: 'in %s',
                            past: '%s ago',
                            s: 'a few seconds',
                            ss: '%ds',
                            m: '1m',
                            mm: '%dm',
                            h: '1h',
                            hh: '%dh',
                            d: '1d',
                            dd: '%dd',
                        },
                    })
                    return messageSentTime.fromNow(true)
                }
            }
            return ''
        },
    },
    methods: {
        message_clickHandler() {
            if (this.disabled) return
            this.$emit('openChannel', this.channel)
        },
    },
    watch: {},
}
</script>
<style lang="scss" scoped>
.message-list-item {
    max-width: calc(100% - 4px);
    padding: 12px 0;
    margin-right: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
        background-color: #e9f4ff;
        cursor: pointer;
    }

    .p-avatar {
        margin-right: 16px;
        padding: 12px;

        &.read ::v-deep .p-badge {
            display: none;
        }
    }

    .message-body {
        min-width: 210px;
        max-width: 210px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .profile-name {
            font-weight: 500;
            font-size: 14px;
        }

        .message-content {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &.unread {
                font-weight: 500;
            }
        }
    }

    .message-sent {
        width: 100%;
        text-align: end;
    }

    &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
}
</style>
