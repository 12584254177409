import ChatAdapter from "@/lib/ChatAdapter";

export default function (context, inject) {
  const { $config, app } = context;
  inject('chat', new ChatAdapter(
      app,
      $config.MESSAGING_WEBSOCKET_URL,
  {
        DEBUG: $config.MESSAGING_DEBUG,
        MAX_CHANNELS: parseInt($config.MESSAGING_MAX_CHANNELS),
        MESSAGES_BATCH_SIZE: parseInt($config.MESSAGING_MESSAGES_BATCH_SIZE),
        RECONNECT_DELAY_MS: parseInt($config.MESSAGING_RECONNECT_DELAY_MS),
        HEARTBEAT_INCOMING_MS: parseInt($config.MESSAGING_HEARTBEAT_INCOMING_MS),
        HEARTBEAT_OUTGOING_MS: parseInt($config.MESSAGING_HEARTBEAT_OUTGOING_MS)
      }
  ));
}