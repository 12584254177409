import { kebabCase } from '@/helpers'

export const state = () => ({
  loading: false,
  carePlan: {},
  carePlanTypes: [],
  sidebarCarePlanItems: [],
  activeCarePlanSection: null,
  carePlanSectionNotesHistory: [],
  currentNote: null,
  carePlanNotes: [],
})
export const getters = {
  fetchActiveCarePlanSection: (state) => {
    return state.carePlan.sections?.find(section => section.id === state.activeCarePlanSection);
  },
  fetchCarePlanSectionFromId: (state) => (sectionId) => {
    return state.carePlan.sections?.find(section => section.id === sectionId);
  },
  fetchHistoryForCurrentSection: (state) => {
    return state.carePlanSectionNotesHistory.filter(note => note.section.id === state.activeCarePlanSection);
  }
}
export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setCarePlan(state, carePlan) {
    state.carePlan = carePlan;
  },
  setCarePlanTypes(state, carePlanTypes) {
    state.carePlanTypes = carePlanTypes;
  },
  setSidebarCarePlanItems(state, items) {
    state.sidebarCarePlanItems = items;
  },
  setActiveCarePlanSection(state, sectionId) {
    state.activeCarePlanSection = sectionId;
  },
  setCarePlanSectionNotesHistory(state, history) {
    state.carePlanSectionNotesHistory = history;
  },
  setCurrentNote(state, note) {
    state.currentNote = note;
  },
  setCarePlanNotes (state, notes) {
    state.carePlanNotes = [...notes];
  }
}
export const actions = {
  async initProfileCarePlan ({ dispatch, commit }, { profileId }) {
    commit('setLoading', true);
    await Promise.all([dispatch('fetchProfileCarePlan', profileId), dispatch('fetchCarePlanTypes'), dispatch('fetchPatientCarePlanNotes', { profileId })]);
    commit('setLoading', false);
  },

  async fetchProfileCarePlan({ commit, dispatch }, profileId) {
    const response = await this.$apiv2.getPatientCarePlan(profileId);
    commit('setCarePlan', response);
  },

  async fetchCarePlanTypes({ commit }) {
    const response = await this.$apiv2.getCarePlanTypes();
    commit('setCarePlanTypes', response);
  },
  // This function is not used in the codebase
  applyActiveCarePlanSection({ state, commit }, { sectionType }) {
    let activeSectionId = state.carePlan.sections?.find(section => section.type === sectionType)?.id;

    commit('setActiveCarePlanSection', activeSectionId);
  },
  applyActiveCarePlanSectionById({ commit }, { sectionId }) {
    commit('setActiveCarePlanSection', sectionId);
  },
  async createCarePlanSectionNote ({ dispatch }, { profileId, sectionId, text }) {
    console.log('createCarePlanSectionNote', profileId, sectionId, text);
    let payload = {
      text: text
    };
    await this.$apiv2.createPatientCarePlanSectionNote(profileId, sectionId, payload);
    await dispatch('fetchProfileCarePlan', profileId);
    await dispatch('fetchPatientCarePlanSectionNotesHistory', { profileId, sectionId });
  },
  async updateCarePlanSectionNote({ dispatch }, { profileId, updatedSection }) {
    await this.$apiv2.updatePatientCarePlanSection(profileId, updatedSection);

    await dispatch('fetchProfileCarePlan', profileId);
  },
  async updateCarePlanSectionHistoryNote ({ dispatch, commit }, { profileId, sectionId, noteId, text }) {
    let payload = {
      id: noteId,
      text: text
    };
    await this.$apiv2.updatePatientCarePlanHistoryNote(profileId, sectionId, payload);

    await dispatch('fetchProfileCarePlan', profileId);
    await dispatch('fetchPatientCarePlanSectionNotesHistory', { profileId, sectionId });
  },

  async deleteCarePlanSectionHistoryNote ({ dispatch }, { profileId, sectionId, noteId }) {
    await this.$apiv2.deletePatientCarePlanHistoryNote(profileId, sectionId, noteId);

    await dispatch('fetchProfileCarePlan', profileId);
    await dispatch('fetchPatientCarePlanSectionNotesHistory', { profileId, sectionId });
  },

  async sortCarePlanSectionHistoryByCreatedDate({ commit }, sectionHistory ) {
    let sortedSectionHistory = sectionHistory.sort((a, b) => {
      return new Date(b.createdDate) - new Date(a.createdDate);
    });
    commit('setCarePlanSectionNotesHistory', sortedSectionHistory);
  },

  async fetchPatientCarePlanSectionNotesHistory({ dispatch }, { profileId, sectionId }) {
    const response = await this.$apiv2.getPatientCarePlanSectionNotes(profileId, sectionId);
    await dispatch('sortCarePlanSectionHistoryByCreatedDate', response);
  },

  async fetchPatientCarePlanNotes({ commit }, { profileId }) {
    const response = await this.$apiv2.getPatientCarePlanNotes(profileId);
    commit('setCarePlanNotes', response);
  },

  async createCarePlanNote ({ dispatch }, { profileId, text }) {
    let payload = {
      text: text
    };
    await this.$apiv2.createPatientCarePlanNote(profileId, payload);
    await dispatch('fetchPatientCarePlanNotes', { profileId });
  },

  async updateCarePlanNote ({ dispatch }, { profileId, noteId, text }) {
    let payload = {
      id: noteId,
      text: text
    };
    await this.$apiv2.updatePatientCarePlanNote(profileId, payload);
    await dispatch('fetchPatientCarePlanNotes', { profileId });
  },

  async deleteCarePlanNote ({ dispatch }, { profileId, noteId }) {
    await this.$apiv2.deletePatientCarePlanNote(profileId, noteId);
    await dispatch('fetchPatientCarePlanNotes', { profileId });
  },

  async clearCarePlan({ commit }) {
    commit('setCarePlan', {});
  },
  
}